
  import ProviderInfo from '~/components/external-integrations/ProviderInfo.vue'

  export default {
    name: `ProviderDialog`,
    components: { ProviderInfo },
    props: {},
    data() {
      return {
        toggleDialog: false,
        formErrors: {}
      }
    },
    computed: {
      provider() {
        return this.$store.getters[`integrations/selectedProvider`]
      }
    },
    methods: {
      openDialog() {
        this.toggleDialog = true;
      },
      async save() {
        try {
          const providers = this.provider.isInstalled
            ? await this.$api.integrations.updateProvider(this.provider)
            : await this.$api.integrations.createProvider(this.provider)

          this.$store.commit(`integrations/setProviders`, providers)

          this.$flashMessage.success({
            title: `${this.$t(`messages.success`)}`
          })
          this.toggleDialog = false
          this.formErrors = {}
        } catch (error) {
          if (error.response && error.response.status === 400) {
            this.formErrors = error.data
          } else {
            this.$flashMessage.error({
              title: `${this.$t(`messages.error`)}`,
              message: this.$t(`messages.unexpected_error`)
            })
          }
        }
      }
    }
  }
