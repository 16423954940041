
import ProviderDialog from "~/components/external-integrations/ProviderDialog.vue";

export default {
  name: `ProviderDetailed`,
  components: {ProviderDialog},
  props: {
    selectedProvider: {
      type: Object,
      required: true
    }
  },
  methods: {
    goBack() {
      this.$store.commit(`integrations/setSelectedProvider`, undefined)
    }
  }
}
