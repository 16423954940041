
export default {
  name: `ProviderInfo`,
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    formErrors: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      apiKey: ``,
      baseUri: ``
    }
  },
  computed: {
    provider() {
      return this.$store.getters[`integrations/selectedProvider`]
    }
  },
  watch: {
    apiKey(newVal) {
      this.updateProviderData(`api_key`, newVal)
    },
    baseUri(newVal) {
      this.updateProviderData(`base_uri`, newVal)
    }
  },
  mounted() {
    this.apiKey = this.provider?.api_key || ``
    this.baseUri = this.provider?.base_uri || ``
  },
  methods: {
    updateProviderData(field, value) {
      this.$store.commit(`integrations/updateProviderField`, {field, value})
    }
  }
}
